import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as File } from 'assets/file.svg';
import { ReactComponent as Trash } from 'assets/trash2.svg';
import { DeleteModal } from 'components/new/DeleteModal/DeleteModal';
import request from 'utils/request';

import styles from './DocumentRow.module.css';
import { formatRelativeTime, hasMoreThan48HoursPassed } from './utils';

export interface DocumentProps {
  id: string;
  name: string;
  type: string;
  uploadedBy: string;
  uploadTime: Date;
  format: string;
  onDelete: () => void;
}

export const DocumentRow: React.FC<DocumentProps> = ({
  id,
  name,
  type,
  uploadedBy,
  uploadTime,
  format,
  onDelete,
}) => {
  const { patientId } = useParams<{ patientId: string }>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const getDocumentLink = () => {
    request({
      method: 'get',
      url: `/documents/${id}`,
      params: {
        patient_id: patientId,
      },
    })
      .then(({ data: { data } }) => {
        window.open(data.document.url, '_blank');
      })
      .catch((error) => console.info(error));
  };

  const deleteDocument = () => {
    setOpenDeleteModal(false);
    request({
      method: 'delete',
      url: `/documents/${id}`,
      params: {
        patient_id: patientId,
      },
    })
      .then(() => onDelete())
      .catch((error) => console.info(error));
  };

  const renderDeleteButton = () => {
    if (!name || hasMoreThan48HoursPassed(uploadTime)) {
      return <div />;
    }
    return (
      <>
        <Trash onClick={() => setOpenDeleteModal(true)} />
        {renderDeleteModal()}
      </>
    );
  };

  const renderDeleteModal = () => {
    if (!openDeleteModal) {
      return null;
    }
    return (
      <DeleteModal
        description={`Please confirm you want to delete ${name}`}
        onDelete={() => {
          deleteDocument();
          onDelete();
        }}
        onCancel={() => setOpenDeleteModal(false)}
      />
    );
  };

  return (
    <div className={styles.row}>
      <div className={styles.name}>
        {name && <File />}
        <div className={styles.link}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              getDocumentLink();
            }}
            title={name}
            className={styles.link}
          >
            {name}
          </a>
        </div>
      </div>
      <div className={styles.type} title={type}>
        {type}
      </div>
      <div className={styles.type} title={format}>
        {format}
      </div>
      <div className={styles.uploadedBy} title={uploadedBy}>
        {uploadedBy}
      </div>
      <div className={styles.uploadTime}>{name && formatRelativeTime(uploadTime)}</div>
      <div className={styles.deleteButton}>{renderDeleteButton()}</div>
    </div>
  );
};
