import classNames from 'classnames';
import React from 'react';
import Select from 'react-dropdown-select';
import styled from 'styled-components';

import { UppercaseText, RegularText } from 'common/texts';

function DropdownHandleRenderer() {
  return null;
}

/**
 *
 * @param {*} param0
 * @param {string} param0.label
 * @param {string} param0.className
 * @param {string} param0.placeholder
 * @param {boolean} param0.searchable
 * @param {object[]} param0.options
 * @param {object[]} param0.values
 * @param {function} param0.changeHandler
 * @param {boolean} param0.isLoading
 * @param {string} param0.hint
 * @param {string} param0.isRequred
 * @returns
 */
export default function CustomMultiSelect({
  label = '',
  values,
  searchable,
  options,
  changeHandler,
  className = '',
  placeholder = '',
  isLoading,
  hint = 'You can select multiple answers here',
  isRequred = false,
  isValid = true,
}) {
  if (isLoading) {
    return (
      <StyledLoader className={className}>
        {label && <div className="loader-label" />}
        <div className="loader-select" />
        <div className="loader-hint" />
      </StyledLoader>
    );
  }

  return (
    <StyledWrapper className={className}>
      {label && (
        <UppercaseText className="g-mb-10">
          {label}
          {isRequred && <span className="required">*</span>}
        </UppercaseText>
      )}

      <StyledSelect
        values={values}
        options={options}
        placeholder={placeholder}
        onChange={changeHandler}
        searchable={searchable}
        multi
        closeOnSelect
        dropdownHandleRenderer={DropdownHandleRenderer}
        isValid={isValid}
        className={classNames({
          'invalid-select': !isValid,
        })}
      />
      <RegularText className="hint">{hint}</RegularText>
    </StyledWrapper>
  );
}

const StyledLoader = styled.div`
  .loader-label {
    height: 1.8rem;
    width: 70%;
    margin-bottom: 1rem;
    background: var(--pale-silver);
  }
  .loader-select {
    height: 4.8rem;
    background: var(--pale-silver);
  }
  .loader-hint {
    height: 2.4rem;
    width: 70%;
    margin-top: 0.5rem;
    background: var(--pale-silver);
  }
`;

const StyledWrapper = styled.div`
  width: 100%;
  .required {
    display: inline-block;
    color: var(--error-red);
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-left: 1px;
  }
  .hint {
    margin-top: 0.5rem;
  }
`;

const StyledSelect = styled(Select)`
  &.react-dropdown-select {
    border: solid 1px var(--pale-silver);
    padding: 0.5rem 0 0 0.5rem;
    min-height: auto;
    &:hover {
      border: solid 1px var(--silver-gray);
    }
    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
    }
    &:focus-within {
      border: solid 1px var(--pale-silver);
      box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
    }
  }

  &.invalid-select {
    border-color: var(--error-red);
    &:hover {
      border: solid 1px var(--error-red);
    }
    &:focus {
      box-shadow: 0 0 0 0.1rem var(--error-red);
    }
    &:focus-within {
      border: solid 1px var(--error-red);
      box-shadow: 0 0 0 0.1rem var(--error-red);
    }
  }

  .react-dropdown-select-content {
    font-size: 1.3rem;
    line-height: 3.6rem;
    .react-dropdown-select-input {
      ${({ searchable }) => !searchable && 'width: 100%;'}
      font-size: 1.3rem;
      margin: 0 0.5rem 0.5rem 1rem;
      &::placeholder {
        font-size: 1.3rem;
        font-weight: 300;
      }
    }
    .react-dropdown-select-input[placeholder=''] {
      ${({ searchable }) => !searchable && 'width: 0;'}
    }
    .react-dropdown-select-option {
      background: var(--dusty-violet);
      margin: 0 0.5rem 0.5rem 0;
      padding: 0 1.2rem 0 1.4rem;
      border: solid 1px var(--pale-silver);
      border-radius: 0;
      line-height: 3.4rem;
      .react-dropdown-select-option-label {
        font-size: 1.3rem;
        padding-right: 2.5rem;
        font-weight: 300;
        color: var(--white);
      }
      .react-dropdown-select-option-remove {
        width: 1.4rem;
        height: 100%;
        display: block;
        margin: auto;
        padding-top: 0.2rem;
        color: var(--white);
        font-size: 2.5rem;
        font-weight: 500;
      }
    }
  }
  .react-dropdown-select-dropdown {
    border: solid 1px var(--pale-silver);
    border-bottom: none;
    .react-dropdown-select-item {
      font-size: 1.3rem;
      line-height: 3rem;
      border-bottom: solid 1px var(--pale-silver);
    }
    .react-dropdown-select-item-selected {
      color: var(--white);
      background: var(--dusty-violet);
    }
  }
  .react-dropdown-select-no-data {
    font-size: 1.3rem;
    color: var(--deep-teal);
  }
`;
