import get from 'lodash/get';
import React from 'react';
import ImageUploading from 'react-images-uploading';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';
import { RegularBigText } from 'common/texts';
import UserAvatar from 'common/UserAvatar';
import { useLocalProfile, useUploadedAvatar, useSetUploadedAvatar } from 'hooks/useProfile';

const maxMbFileSize = 1 * 1024 * 1024; // 1MB

export default React.memo(function ProfilePhoto() {
  const uploadedAvatar = useUploadedAvatar();
  const setUploadedAvatar = useSetUploadedAvatar((prev, next) => next, []);
  const firstName = useLocalProfile((current) => get(current, 'first_name', ''));
  const lastName = useLocalProfile((current) => get(current, 'last_name', ''));
  const avatar = useLocalProfile((current) => get(current, 'avatar'));

  const avatarSrc = uploadedAvatar || avatar;

  React.useEffect(() => {
    return () => setUploadedAvatar(null);
  }, []);

  const handleImageUpload = React.useCallback(
    (images) => {
      if (images.length > 0) {
        setUploadedAvatar(images[0].dataURL);
      } else {
        setUploadedAvatar(null);
      }
    },
    [setUploadedAvatar],
  );

  const imageValue = React.useMemo(() => {
    if (uploadedAvatar) {
      return [{ dataURL: uploadedAvatar }];
    }
    if (avatar) {
      return [{ dataURL: avatar }];
    }
    return [];
  }, [avatar, uploadedAvatar]);

  return (
    <StyledWrapper>
      <RegularBigText>Profile photo</RegularBigText>

      <div className="g-d-flex g-align-center g-mt-15 g-mb-15">
        <StyledUserAvatar avatar={avatarSrc} firstName={firstName} lastName={lastName} />
        <ImageUploading
          value={imageValue}
          onChange={handleImageUpload}
          maxFileSize={maxMbFileSize}
          acceptType={['jpg', 'jpeg', 'png']}
          multiple={false}
        >
          {({ onImageUpload, errors }) => (
            <div>
              <LinkButton onClick={onImageUpload}>Upload</LinkButton>
              <div className="hint">Recommended size 100x100</div>
              {errors && (
                <div className="error">
                  {errors.maxFileSize && (
                    <>File size too large. The limit is 1 MB. Please choose a smaller image.</>
                  )}
                  {errors.acceptType && (
                    <>Unsupported file type. Please upload a JPG, JPEG, or PNG image.</>
                  )}
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .hint {
    font-size: 1.3rem;
    line-height: 2.4rem;
    color: #000;
    opacity: 0.5;
  }

  .error {
    color: red;
    font-size: 1.2rem;
  }
`;

const StyledUserAvatar = styled(UserAvatar)`
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
`;
