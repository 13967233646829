export const formatRelativeTime = (utcDate: string | Date): string => {
  const seconds = getTimeDifferenceInSeconds(utcDate);
  if (seconds < 60) {
    return `${seconds} seconds ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  }

  const days = Math.floor(hours / 24);
  if (days < 30) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return `${months} ${months === 1 ? 'month' : 'months'} ago`;
  }

  const years = Math.floor(months / 12);
  return `${years} ${years === 1 ? 'year' : 'years'} ago`;
};

export const getExtension = (fileName: string): string => {
  const dotIndex = fileName.lastIndexOf('.');
  return dotIndex !== -1 ? fileName.slice(dotIndex + 1) : '';
};

export const getTimeDifferenceInSeconds = (utcDate: string | Date): number => {
  const date = new Date(utcDate).getTime();
  const now = Date.now();
  return Math.floor((now - date) / 1000);
};

export const hasMoreThan48HoursPassed = (utcDate: string | Date): boolean => {
  const timeDifferenceInSeconds = getTimeDifferenceInSeconds(utcDate);
  const fortyEightHoursInSeconds = 48 * 60 * 60;
  return timeDifferenceInSeconds > fortyEightHoursInSeconds;
};
