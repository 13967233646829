import React from 'react';

import { Modal } from 'components/new/Modal/Modal';

import styles from './DeleteModal.module.css';

interface DeleteModalProps {
  description: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({ description, onDelete, onCancel }) => {
  return (
    <Modal title="Delete Document" closeModal={onCancel} minWidth={300} maxWidth={350}>
      <div className={styles.modalBody}>
        <p>{description}</p>
        <div className={styles.modalActions}>
          <button className={styles.deleteButton} onClick={onDelete}>
            Delete
          </button>
          <button className={styles.cancelButton} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
