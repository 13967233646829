import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.css';

interface ModalProps {
  children: React.ReactNode;
  background?: string;
  minWidth?: number;
  maxWidth?: number;
  title?: string;
  closeModal?: () => void;
  withSeparator?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  background = '#FFFFFF',
  minWidth,
  maxWidth,
  title = '',
  closeModal,
  withSeparator,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
      closeModal?.();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      className={styles.wrapper}
      onClick={(e) => {
        if (!closeModal) {
          return;
        }
        e.stopPropagation();
        closeModal();
      }}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          if (closeModal) {
            closeModal();
          }
        }
      }}
    >
      <div
        className={styles.container}
        style={{
          background,
          minWidth: minWidth ? `${minWidth}px` : undefined,
          maxWidth: maxWidth ? `${maxWidth}px` : undefined,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
        </div>
        {withSeparator && <hr className={styles.separator} />}
        {children}
      </div>
    </div>,
    document.getElementById('modal')!,
  );
};
