import React from 'react';
import styled from 'styled-components';

import CustomMultiSelect from 'common/CustomMultiSelect';

import request from '../../../utils/request';

type Option = { label: string; value: string };

type StateLicensesProps = {
  isLoading: boolean;
  country: string;
  qualificationIds: number[];
  stateLicenses: string[];
  onChangeLicense: (states: string[]) => void;
};

export default function StateLicenses({
  isLoading,
  country,
  qualificationIds,
  stateLicenses,
  onChangeLicense,
}: StateLicensesProps) {
  const [states, setStates] = React.useState<Option[]>([]);

  const setStateLicenses = React.useCallback((option: Option[]) => {
    onChangeLicense(option.map(({ value }) => value));
  }, []);

  React.useEffect(() => {
    request({
      url: `/options/states?country=${country || 'USA'}`,
      method: 'GET',
    }).then(({ data }) => setStates(data));
  }, [country]);

  const normalizedStateLicenses = React.useMemo(() => {
    if (!states.length) {
      return [];
    }
    return stateLicenses
      .map((value) => states.find((state) => state.value === value))
      .filter(Boolean);
  }, [states, stateLicenses]);

  if (isLoading || qualificationIds.includes(1) || qualificationIds.length === 0) {
    return null;
  }

  const isStateLicensesValid = stateLicenses.length > 0;

  return (
    <StyledWrapper>
      <CustomMultiSelect
        className="state-licenses g-mt-25"
        label="State licenses"
        placeholder="Indicate in which states you have a license"
        hint="State-specific and mandated licensure, registration, or certification for healthcare professionals"
        searchable={false}
        values={normalizedStateLicenses}
        options={states}
        changeHandler={setStateLicenses}
        isLoading={!states}
        isRequred={true}
        isValid={isStateLicensesValid}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .state-licenses {
    .react-dropdown-select {
      max-width: 50%;
      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
    }
  }
`;
